import 'normalize.css'
import 'animate.css'
import './assets/m-game.css'
import 'notyf/notyf.min.css'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import locales from '@/locales'
import { createPinia } from 'pinia'
import { useGameStore } from '@/stores/gameStore'
import { errorFunctions } from '@/services/errors'
import { api } from '@/services/api'
import { timeFunctions } from '@/services/time'

import App from './App.vue'
import { router, routerInit } from './router/router'

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: locales
})
const app = createApp(App)

app.use(i18n)
app.use(createPinia())
app.use(router)
app.mount('#app')

const gameStore = useGameStore()
errorFunctions.Init(gameStore)
timeFunctions.Init(gameStore)
api.Init(gameStore)
routerInit(gameStore)

router.isReady().then(() => {
  if (router.currentRoute.value.name !== 'login' && router.currentRoute.value.name !== 'sharedLink')
    gameStore.GetState(true)
})
setInterval(timeFunctions.Tick, 1000)
