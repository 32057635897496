<!-- Выбор этапа -->

<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

const ClickChooseStage = (stageId) => {
  if (gameStore.allowReturnToPreviousStage || gameStore.progressStatus == 'choose-stage')
    gameStore.ChooseStage(stageId)
  else {
    gameStore.dialogs.sureChooseStage.open = 1
    gameStore.dialogs.sureChooseStage.toStageId = stageId
  }
}
</script>

<template>
  <div class="tab-wrapper" id="tabChooseStage">
    <!-- Заголовок -->
    <div class="choose-stage-name-container">
      <h1 class="tab-h1">{{ $t('chooseStage.title') }}</h1>
    </div>

    <div
      v-if="gameStore.progressStatus == 'choose-stage' || gameStore.allowChooseStage == '1'"
      class="choose-stage stage"
    >
      <div class="tab-wrapper">
        <!-- Кнопки выбора этапа -->
        <div class="choose-stage-btn-container">
          <button
            v-for="stage in gameStore.stagesForChoose"
            type="button"
            class="btn btn-choose-stage"
            :disabled="stage.used == 1 || stage.id == gameStore.stage.id"
            @click="ClickChooseStage(stage.id)"
          >
            {{ stage.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.choose-stage-name-container,
.choose-stage-btn-container {
  padding: 0 17px;
  margin: 0;
  margin-bottom: 17px;
}
.btn-choose-stage {
  margin-top: 10px;
  background-color: #fddb1b;
  width: 100%;
  border: 0;
  padding: 12px;
}
.btn-choose-stage:disabled {
  background: #abb0bc;
  cursor: default;
}
.btn-choose-stage:first-child {
  margin-top: 0;
}
.btn-choose-stage:last-child {
  margin-bottom: 17px;
}

/* > 1024 */
@media (min-width: 1024px) {
}
</style>
