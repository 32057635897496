<!-- Вкладка чатов -->

<script setup></script>

<template>
  <div class="tab-wrapper" id="tabChats">
    <!-- Заголовок -->
    <div class="chats-name-container">
      <h1 class="tab-h1">{{ $t('chats.title') }}</h1>
    </div>

    <div class="chats-content-container content-text">{{ $t('chats.disabled') }}</div>
  </div>
</template>

<style scoped>
.content-text {
  font-size: 18px;
  word-break: break-word;
}
.chats-content-container {
  margin-top: 17px;
}
.chats-name-container,
.chats-content-container,
.content-text,
.content-header {
  padding: 0 17px;
  margin: 0;
  margin-bottom: 17px;
}

/* > 1024 */
@media (min-width: 1024px) {
}
</style>
