export default {
  general: {
    copy: 'Copy',
    copied: 'Copied!',
    next: 'Next'
  },
  tabs: {
    tipsMobile: 'tips',
    tipsDesktop: 'Tips',
    gameMobile: 'game',
    gameDesktop: 'Game',
    messagesMobile: 'messages',
    messagesDesktop: 'Messages',
    stagesMobile: 'stages',
    stagesDesktop: 'Stages'
  },
  errors: {
    error: 'Error',
    noAuth: 'Please use the link to log in',
    lostConnection: 'Internet connection lost'
  },
  timerToGame: {
    gameWillStartAt: 'Game will start at'
  },
  sharedLink: {
    errors: {
      title: 'Oops',
      teamLimit:
        'Unfortunately, the participant limit has been exceeded. Please contact the organizer.',
      wrongData: 'The link is incorrect or not currently active. Please contact the organizer.'
    },
    wantContinue: 'Do you want to continue?',
    wantContinueDesc:
      'You have previously logged in on this device. Return to the previous event or start a new one?',
    continueOldSession: 'Continue previous',
    startNewSession: 'Start new',
    sendLinkToTeam: 'Send the link to your team members',
    sendLinkToTeamDesc: 'Then you can log in from multiple devices simultaneously.'
  },

  testConsole: {
    title: 'Test mode',
    description: 'This panel is only available in test mode. It is not accessible to players.',
    reset: 'Start over',
    agreeReset: 'Start the game anyway'
  },
  time: {
    moreThen: 'more than',
    sec: 'sec',
    min: 'min',
    hr: 'hr',
    dz: 'day(s)'
  },
  progressBar: {
    untilStageStop: 'Time left until stage ends: '
  },
  stageTelemetry: {
    timeOnStage: 'Time on stage:',
    correctAnswers: 'Correct answers:',
    answers: 'Answers:',
    details: 'Details'
  },
  answersLogs: {
    title: 'History of entered answers',
    empty: 'No entries yet',
    btnBack: 'Return to task',
    correct: 'correct',
    wrong: 'incorrect',
    again: 'repeat'
  },
  chats: {
    title: 'Chats',
    disabled: 'Chats are temporarily unavailable'
  },
  chooseStage: {
    title: 'Choose a stage'
  },
  stage: {
    answerInputPlaceholder: 'Enter answer',
    send: 'Send',
    correct: 'correct',
    wrong: 'incorrect',
    again: 'repeat'
  },
  tips: {
    title: 'Tips',
    empty: 'No tips yet. You will receive a notification if they appear.'
  },
  dialogs: {
    sureChooseStage: {
      title: 'Are you sure you want to proceed?',
      body: 'You cannot go back',
      agree: 'Proceed',
      cancel: 'Cancel'
    },
    sureNextStage: {
      title: 'Are you sure you want to proceed?',
      body: 'You cannot go back',
      agree: 'Proceed',
      cancel: 'Cancel'
    }
  },
  gameOver: {
    promo:
      'On the 12codes platform, you can create your own game, quest, or excursion! More than 40 cities are already using it',
    landingLink: 'https://12codes.com'
  }
}
