<!-- Header мобилка -->

<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div class="onstage-data">
    <div class="onstage-time" v-if="gameStore.stageTelemetry.stopwatch">
      <span class="desktop-onstage-time-text">
        {{ $t('stageTelemetry.timeOnStage') }}&nbsp;&nbsp;
      </span>
      <span>{{ gameStore.stageTelemetry.stopwatch }}</span>
      <span v-if="gameStore.stageTelemetry.ap">
        {{ '&nbsp;/&nbsp;' + gameStore.stageTelemetry.ap }}
      </span>
    </div>
    <div class="onstage-answers" v-if="gameStore?.stage?.reqAnswers">
      <span class="desktop-onstage-answers-text"
        >{{ $t('stageTelemetry.correctAnswers') }}&nbsp;
      </span>
      <span class="mobile-onstage-answers-text"> {{ $t('stageTelemetry.answers') }}&nbsp; </span>
      <span>{{ gameStore?.stage?.correctAnswers }}/{{ gameStore?.stage?.reqAnswers }}</span>
    </div>
    <div class="onstage-answers" v-if="gameStore.routePath !== 'logs'">
      <RouterLink to="/logs" class="btnAnswersLog"> {{ $t('stageTelemetry.details') }} </RouterLink>
    </div>
  </div>
</template>

<style scoped>
.onstage-data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 17px;
  color: #636363;
  font-size: 14px;
}
.onstage-time {
  /* margin-right: 17px; */
}
.desktop-onstage-time-text {
  display: none;
}
.desktop-onstage-answers-text {
  display: none;
}
.mobile-onstage-data {
  margin-top: 12px;
}
.btnAnswersLog {
  padding: 5px 15px;
  border: 1px solid #fddb1b99;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  color: #636363;
}

@media (min-width: 1024px) {
  .desktop-onstage-time-text,
  .desktop-onstage-answers-text {
    display: block;
  }
  .desktop-onstage-data .onstage-data {
    display: flex;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  .desktop-onstage-data .onstage-time,
  .onstage-answers {
    display: flex;
  }
  .mobile-onstage-answers-text {
    display: none;
  }
  .mobile-onstage-data {
    display: none;
  }
  .btnAnswersLog {
    padding: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    text-decoration: underline;
    color: #ffffff;
  }
  .btnAnswersLog:hover {
    background: transparent;
  }
}
</style>
