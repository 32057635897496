// Модуль работы с сетевыми запросами
import { errorFunctions } from '@/services/errors'
import PQueue from 'p-queue'
const queue = new PQueue({ concurrency: 1 })

let gameStore = null

const Init = async (appGameStore) => {
  gameStore = appGameStore
}

// Является ли строка Json
const IsJsonString = (str) => {
  if (typeof str !== 'string') return false
  if (str.length > 0 && str[0] !== '{' && str[0] !== '[') return false

  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

// Преобразуем контент
const GetFileExt = (filePath) => {
  if (!filePath || typeof filePath !== 'string') return false

  const segments = filePath.split('.')
  if (segments.length === 1 || segments[0] === '' || segments[segments.length - 1] === '')
    return false

  return segments[segments.length - 1]
}

// Преобразуем контент
const ParseContentBlocks = (blocks) => {
  let html = ''

  for (let block of blocks) {
    let listHTML = ''

    switch (block.type) {
      case 'header':
        html +=
          `<div class="content-header">` +
          '<h' +
          block.data.level +
          '>' +
          block.data.text +
          '</h' +
          block.data.level +
          '>' +
          `<div>`
        break
      case 'paragraph':
        html += `<div class="content-text content-block">
                        <p>${block.data.text}</p>
                    </div>`
        break
      case 'list':
        listHTML = ''
        for (let item of block.data.items) {
          listHTML += `<li>${item}</li>`
        }
        if (block.data.style == 'ordered') listHTML = `<ol>${listHTML}</ol>`
        else listHTML = `<ul>${listHTML}</ul>`

        html += `<div class="content-list content-block">
                        ${listHTML}
                    </div>`
        break
      case 'image':
        html += `<div class="content-image content-block">
                        <img src="${block.data.file.url}">
                    </div>`
        break
      case 'embed':
        html +=
          '<div class="resp-container content-block" style="padding-top: ' +
          (block.data.height / block.data.width) * 100 +
          '%;"><iframe class="resp-iframe content-block" src="' +
          block.data.embed +
          '" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>'
        break
      case 'raw':
        html += '<div class="content-block overflow-hidden">' + block.data.html + '</div>'
        break
      case 'attaches':
        if (GetFileExt(block.data.file.url) == 'mp3')
          html += `<div class="content-audio content-block">
                        <audio controls src="${block.data.file.url}">
                          <a href="${block.data.file.url}">Download audio</a>
                        </audio>
                    </div>`
        else if (GetFileExt(block.data.file.url) == 'mp4')
          html += `<div class="content-video content-block">
                        <video controls playsinline >
                          <source src="${block.data.file.url}" type="video/mp4">
                          Download the <a href="/media/cc0-videos/flower.mp4">MP4 video</a>.
                        </video>
                    </div>`

        break
    }
  }
  return html
}

// Преобразуем контент
const PrepareContent = (data) => {
  if (Array.isArray(data)) {
    // обходим массив
    data.forEach(function (item, index) {
      data[index] = PrepareContent(item)
    })
    return data
  } else if (typeof data === 'object' && data !== null) {
    for (let key in data) {
      // обходим свойства объекта
      if (key === 'blocks') data[key] = ParseContentBlocks(data[key])
      else data[key] = PrepareContent(data[key])
    }
    return data
  } else if (IsJsonString(data)) {
    return PrepareContent(JSON.parse(data))
  } else {
    return data
  }
}

const AjaxProcessor = async (
  url,
  data,
  successFunction,
  failFunction = null,
  finallyFunction = null
  //   element = {
  //     vmId: null,
  //     textStart: '',
  //     textProccess: ''
  //   }
) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    })

    gameStore.showFetchError = 0
    if (!response.ok) {
      errorFunctions.HandlerAjaxError('no_connection')
      console.log('no_connection')
      if (failFunction) failFunction(['no_connection'])
    }

    const responseText = await response.text()
    if (!IsJsonString(responseText)) {
      errorFunctions.HandlerAjaxError(responseText)
      console.log(responseText)
      if (failFunction) failFunction(['responseText'])
      return false
    }
    const responseData = JSON.parse(responseText)

    if (responseData.status === 'ok') {
      successFunction(responseData.data, responseData.sendDT)
      if (url.includes('/get-state.php')) {
        // vm.prevSrvOkAnswer = responseData
        // vm.prevSrvOkObj = responseData.data
      }
      //   vm.showFetchError = false
    } else {
      for (let i = 0; i < responseData.errors.length; i++) {
        // errorFunctions.ShowAjaxError(responseData.errors[i])
        console.log(responseData.errors[i])
        if (failFunction) failFunction(responseData.errors[i])
      }
    }
  } catch (error) {
    errorFunctions.HandlerFetchError(error.message)
    console.log(error.message)
    if (failFunction) failFunction([error.message])
  } finally {
    if (finallyFunction) finallyFunction()
    // if (element.vmId)
    //vm.TempReturnText(element.vmId, element.textStart)
  }
}

const AddToQueue = (...args) => {
  queue.add(() => AjaxProcessor(...args))
}

export const api = {
  Init,
  AjaxProcessor,
  AddToQueue,
  PrepareContent
}
