<script setup>
import { ref, computed } from 'vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

const showTestConsole = ref(0)
const isDev = computed(() => {
  return window.location.hostname.includes('localhost')
})
</script>

<template>
  <div class="test-container" v-if="gameStore?.team?.test == '1'">
    <div class="test-header" @click="showTestConsole = !showTestConsole">
      <div class="test-header-link">
        <div>{{ $t('testConsole.title') }}</div>
        <div v-if="!showTestConsole">
          <img class="caret-down" src="@/assets/caret-down.svg " alt="" />
        </div>
        <div v-if="showTestConsole">
          <img class="caret-down" src="@/assets/caret-up.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="test-body" v-show="showTestConsole">
      <div class="test-desc">
        {{ $t('testConsole.description') }}
      </div>
      <button
        class="btn btn-test"
        v-if="gameStore?.progressStatus != 'game-start'"
        v-on:click="gameStore.ResetProgress"
        id="btnResetGame"
      >
        {{ $t('testConsole.reset') }}
      </button>
      <button
        class="btn btn-test"
        v-if="gameStore?.progressStatus == 'game-start'"
        v-on:click="gameStore.IgnoreStartTimer"
        id="btnIgnoreStartTimer"
      >
        {{ $t('testConsole.agreeReset') }}
      </button>
      <div v-if="isDev" class="test-dev-info">
        gameId: {{ gameStore?.game?.id }}<br />
        teamId: {{ gameStore?.team?.id }}<br />
        stageId: {{ gameStore?.stage?.id }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.test-container {
  z-index: 2000;
}
.test-header {
  background: #0078f7;
  padding: 16px;
  cursor: pointer;
}
.test-header-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #ffffff;
}
.test-header-link > * {
  margin: 0 4px;
}
.test-body {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500;
  width: 100%;
  color: #ffffff;
  background: #0078f7;
  padding: 16px;
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid #ffffff;
}
.test-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.test-dev-info {
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
}
.btn-test {
  margin-top: 17px;
  background-color: #fddb1b;
  width: 100%;
  max-width: 300px;
  border: 0;
  line-height: 17px;
  text-align: center;
}
</style>
