// Функции, связанные с ошибками
import { Notyf } from 'notyf'
const notyf = new Notyf()

let gameStore = null

const Init = async (appGameStore) => {
  gameStore = appGameStore
}

// Показываем ошибку соединения
const HandlerFetchError = async (error) => {
  LSWriteError(error)
  gameStore.showFetchError = 1
}

// Показываем ошибку
const HandlerAjaxError = async (error) => {
  LSWriteError(error)
  notyf.error(error)
  // storeGame.showFetchError = 1
}

// запись ошибки в LocalStorage
const LSWriteError = async (error) => {
  let now = new Date()
  let record = [now.toISOString(), error]
  let currentErrorLog = []
  if (localStorage.errorLog === undefined) {
    // ?
  } else {
    currentErrorLog = JSON.parse(localStorage.errorLog)
    if (currentErrorLog.length == 20) currentErrorLog.shift()
  }
  currentErrorLog.push(record)
  localStorage.errorLog = JSON.stringify(currentErrorLog)
}

// Запись ошибки соединения в localstorage и показ пользователю
const ShowAjaxError = (error) => {
  notyf.error(error)
  LSWriteError(error)
}

export const errorFunctions = {
  HandlerAjaxError,
  HandlerFetchError,
  Init,
  ShowAjaxError
}
