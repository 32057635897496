<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div
    v-if="gameStore.showFetchError"
    :class="{
      'connection-lost animate__animated animate__slideInDown': gameStore.showFetchError
    }"
  >
    <div>{{ $t('errors.lostConnection') }}</div>
  </div>
</template>

<style scoped>
.connection-lost {
  position: fixed;
  top: 0;
  z-index: 3000;
  display: flex;
  height: 1.6em;
  width: 100%;
  color: #ffffff;
  background: #db3d46;
  font-size: 14px;
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
