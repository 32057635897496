<!-- Вкладка подсказок -->

<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div class="tab-wrapper" id="tabLogs">
    <div class="stageTelemetry">
      <!-- Лог ответов -->
      <div>{{ gameStore.stageTelemetry.stopwatch }}</div>
      <div>{{ gameStore?.stage?.correctAnswers }}/{{ gameStore?.stage?.reqAnswers }}</div>
    </div>
    <div class="answersLog">
      <div class="answersLogHeader">{{ $t('answersLogs.title') }}</div>
      <div
        v-if="gameStore?.teamAnswersList?.length"
        class="answersLogTable"
        v-html="gameStore.PrepareTeamAnswersList()"
      ></div>
      <div class="answersLogTable" v-if="!gameStore?.teamAnswersList?.length">
        {{ $t('answersLogs.empty') }}
      </div>
    </div>
    <div class="answersLogBackContainer">
      <RouterLink to="/" class="btn btn-default answersLogBtnBack">
        {{ $t('answersLogs.btnBack') }}
      </RouterLink>
    </div>
    <!-- <Prorgessbar :inCard="1" /> -->
  </div>
</template>

<style scoped>
.stageTelemetry {
  padding: 0 17px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.answersLog {
  margin: 10px 17px 100px 17px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #eeeeee80;
  padding: 30px 20px;
  border-radius: 10px;
}
.answersLogHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
}
.stageTelemetry > * {
  background: #eeeeee80;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}
.answersLogTable {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 12px;
}
.answersLogBackContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -4px 10px 0px #8e8c8c1a;
  border-radius: 20px;
  justify-content: center;
}
.answersLogBtnBack {
  text-align: center;
  width: calc(100% - 34px);
}

/* > 1024 */
@media (min-width: 1024px) {
  .answersLogBackContainer {
    position: relative;
  }
  .btnAnswersLog {
    padding: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    text-decoration: underline;
    color: #ffffff;
  }
  .btnAnswersLog:hover {
    background: transparent;
  }
  .answersLogBackContainer {
    box-shadow: none;
  }
}
</style>
