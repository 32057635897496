import { createRouter, createWebHistory } from 'vue-router'

let gameStore = null

const routerInit = async (appGameStore) => {
  gameStore = appGameStore
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/sharedLink/:token/:lng',
      name: 'sharedLink',
      component: () => import('../views/SharedLinkView.vue')
    },
    {
      path: '/login/:cacheId/:token/:lng',
      name: 'login',
      component: () => import('../views/LoginView.vue'),
      meta: { noHistory: true }
    },
    {
      path: '/',
      name: 'stage',
      component: () => import('../views/TabStage.vue')
    },
    {
      path: '/tips',
      name: 'tips',
      component: () => import('../views/TabTips.vue'),
      beforeEnter: (to, from) => {
        gameStore.MarkViewedTips()
        return true
      }
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('../views/TabLogs.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/ProfileView.vue')
    },
    {
      path: '/chats',
      name: 'chats',
      component: () => import('../views/TabChats.vue')
    },
    {
      path: '/chooseStage',
      name: 'chooseStage',
      component: () => import('../views/TabChooseStage.vue')
    }
  ]
})

// Глобальный перехватчик навигации
router.beforeEach((to, from, next) => {
  if (gameStore) {
    let routePath = 'path-error'

    if (to.name === 'stage') routePath = 'stage'
    else if (to.name === 'tips') routePath = 'tips'
    else if (to.name === 'chats') routePath = 'chats'
    else if (to.name === 'logs') routePath = 'logs'
    else if (to.name === 'chooseStage') routePath = 'chooseStage'
    else if (to.name === 'sharedLink') {
      routePath = 'sharedLink'
      gameStore.ResetLocalStorage()
      let gameLng = 'en'
      if (to.params?.lng == 'ru') gameLng = 'ru'
      gameStore.SetLocale(gameLng)
    } else if (to.name === 'login') {
      localStorage.teamCacheId = to.params.cacheId
      localStorage.teamToken = to.params.token
      gameStore.ResetLocalStorage()
      next('/')
      return
    }
    // Обновляем текущее состояние маршрута в storeGame
    gameStore.routePath = routePath
  }
  next()
})

export { router, routerInit }
