<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { router } from '@/router/router'
import { api } from '@/services/api'
import { i18n } from '@/main.js'
import { useGameStore } from '@/stores/gameStore'
import { useClipboard } from '@vueuse/core'
const gameStore = useGameStore()
const route = useRoute()

const FailLogin = async (errors) => {
  showError.value = 1
  if (errors.includes('teams_limit')) errorText.value = i18n.global.t('sharedLink.errors.teamLimit')
  if (errors.includes('wrong_data')) errorText.value = i18n.global.t('sharedLink.errors.wrongData')
}

const SuccessGetAccess = async (data) => {
  linkRelative.value = `/login/${data.cacheId}/${data.token}/${route.params.lng}/`
  link.value = window.location.protocol + '//' + window.location.host + linkRelative.value

  step.value = 2
}

const GetAccess = async (successFunction) => {
  await api.AddToQueue(
    import.meta.env.VITE_API_PATH + 'team-token-generate.php',
    {
      gameToken: route.params.token
    },
    successFunction,
    FailLogin,
    async () => {}
  )
}

let link = ref(null) // Ссылка для копирования
let linkRelative = ref(null) // Ссылка относительная (для редиректа)
const { text, copy, copied, isSupported } = useClipboard({ link })
let showError = ref(0)
let errorText = ref('')
let step = ref(1)
if (!gameStore.teamCacheId) step.value = 1
</script>

<template>
  <div v-if="gameStore.routePath == 'sharedLink'" class="sharedlink-root">
    <div class="sharedLogo">
      <img class="" src="@/assets/logo12-white-2.svg" alt="" />
    </div>

    <div class="contentContainer">
      <div v-if="showError" class="options">
        <div id="errorText">
          <h1>{{ $t('sharedLink.errors.title') }}</h1>
          <p>{{ errorText }}</p>
        </div>
      </div>

      <div v-if="!showError" class="options" id="gameInfo">
        <div v-if="step == 1">
          <h1>{{ $t('sharedLink.wantContinue') }}</h1>
          <p>
            {{ $t('sharedLink.wantContinueDesc') }}
          </p>
          <div class="btnGroup">
            <button
              class="btn"
              @click="
                router.push('/').then(() => {
                  router.go(0)
                })
              "
            >
              {{ $t('sharedLink.continueOldSession') }}
            </button>
            <button class="btn btn-default" @click="GetAccess(SuccessGetAccess)">
              {{ $t('sharedLink.startNewSession') }}
            </button>
          </div>
        </div>
        <div v-if="step == 2">
          <h1>{{ $t('sharedLink.sendLinkToTeam') }}</h1>
          <p>{{ $t('sharedLink.sendLinkToTeamDesc') }}</p>
          <div class="btnGroup">
            <input
              type="text"
              id="inputCopyLink"
              :value="link"
              style="margin-bottom: 7px"
              readonly
            />
          </div>
          <div class="btnGroup">
            <button id="btnCopy" class="btn" @click="copy(link)">
              <span v-if="!copied">{{ $t('general.copy') }}</span>
              <span v-else>{{ $t('general.copied') }}</span>
            </button>
            <button
              id="btnStartGame"
              class="btn btn-default"
              @click="
                router.replace(linkRelative).then(() => {
                  router.go(0)
                })
              "
            >
              {{ $t('general.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sharedLogo"></div>
  </div>
</template>

<style scoped>
.options {
  max-width: 100%;
}
.btnGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: space-between;
}
.btnGroup > * {
  flex-grow: 1;
  flex-basis: 0;
  white-space: nowrap;
  min-width: 180px;
}
.btn {
  cursor: pointer;
  border-radius: 10px;
  color: #000000;
  background-color: #fddb1b;
  min-height: 48px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-default {
  background: #eeeeee;
}
#inputCopyLink {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  border: 0;
  background: #eeeeee;
  padding: 0 15px;
  color: #979797;
}

.sharedlink-root {
  min-height: 100vh;
  display: flex;
  background-image: url(/static/img/path-line.svg), linear-gradient(90deg, #05081b 5%, #09124e 100%);
  background-size: 100%, auto;
  background-repeat: no-repeat, no-repeat;
  background-position:
    0% 335px,
    center 120%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.sharedLogo {
  height: 67px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.sharedLogo > img {
  width: 200px;
}
.contentContainer {
  background: #ffffff;
  width: 90%;
  max-width: 570px;
  padding: 40px 30px;
  border-radius: 20px;
  opacity: 0px;
}
.contentContainer h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22.5px;
  margin: 0;
}

.contentContainer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  margin-bottom: 30px;
  color: #636363;
}

.contentContainer .btn {
}

#inputCopyLink {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  border: 0;
  background: #eeeeee;
  padding: 0 15px;
  color: #979797;
}

/* Медиазапросы */
@media (min-width: 1024px) {
  .sharedLogo {
    height: 92px;
  }
  .sharedLogo > img {
    width: 300px;
  }
}
</style>
