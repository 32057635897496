<!-- Вкладка подсказок -->

<script setup>
import Prorgessbar from '@/components/Progressbar.vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div class="tab-wrapper" id="tabTips">
    <!-- Заголовок -->
    <div class="tips-name-container">
      <h1 class="tab-h1">{{ $t('tips.title') }}</h1>
    </div>

    <!-- Подсказок нет -->
    <div class="tips-content-container content-text" v-if="!gameStore.stage.tips.shown.length">
      {{ $t('tips.empty') }}
    </div>

    <!-- Подсказки -->
    <div class="tips-content-container" v-if="gameStore.stage.tips.shown">
      <div v-for="tip in gameStore.stage.tips.shown">
        <div class="tip-container">
          <h2 class="h2-tip-title">{{ tip?.title }}</h2>
          <div v-html="tip?.content?.blocks"></div>
        </div>
      </div>
    </div>
    <Prorgessbar :inCard="1" />
  </div>
</template>

<style scoped>
.content-text {
  font-size: 18px;
  word-break: break-word;
}
.tips-content-container {
  margin-top: 17px;
}
.tip-container {
  border-left: 2px solid #0078f7;
  padding-left: 10px;
  margin-top: 45px;
}
.tips-name-container,
.tips-content-container,
.content-text,
.content-header {
  padding: 0 17px;
  margin: 0;
  margin-bottom: 17px;
}
.h2-tip-title {
  font-size: 18px;
  font-weight: bold;
}

/* > 1024 */
@media (min-width: 1024px) {
  .tips-content-container > div {
    padding-bottom: 14px;
  }
  .tip-container {
    margin: 0;
    /* padding-bottom: 14px; */
  }
}
</style>
