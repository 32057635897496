<!-- Диалог: уверены, что хотите перейти на этот этап? -->

<script setup>
import dialogPolyfill from 'dialog-polyfill'
import { onMounted, watch } from 'vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

let modal = null

onMounted(() => {
  modal = document.querySelector('#dialogSureChooseStage')
  dialogPolyfill.registerDialog(modal)
})

watch(
  () => gameStore.dialogs.sureChooseStage.open,
  (newValue) => {
    if (newValue === 1) {
      modal.showModal()
    } else {
      modal.close()
    }
  }
)
</script>

<template>
  <dialog id="dialogSureChooseStage">
    <div class="modal-box">
      <div class="dialog-header">{{ $t('dialogs.sureChooseStage.title') }}</div>
      <div class="dialog-body">{{ $t('dialogs.sureChooseStage.body') }}</div>
      <div class="dialog-footer">
        <button
          class="btn btn-danger"
          @click="gameStore.ChooseStage(gameStore.dialogs.sureChooseStage.toStageId)"
        >
          {{ $t('dialogs.sureChooseStage.agree') }}
        </button>
        <button class="btn btn-cancel" @click="gameStore.dialogs.sureChooseStage.open = 0">
          {{ $t('dialogs.sureChooseStage.cancel') }}
        </button>
      </div>
    </div>
  </dialog>
</template>

<style scoped>
@media (min-width: 1024px) {
}
</style>
