<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div class="about">
    <h1>Профиль</h1>
    <h2>{{ gameStore.teamName }}</h2>
  </div>
</template>

<style></style>
