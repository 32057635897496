<script setup>
import { RouterView } from 'vue-router'
import TestConsole from '@/components/TestConsole.vue'
import HeaderMobile from '@/components/HeaderMobile.vue'
import HeaderDesktop from '@/components/HeaderDesktop.vue'
import BottomMobile from '@/components/BottomMobile.vue'
import { useGameStore } from '@/stores/gameStore'
import DialogSureChooseStage from '@/components/DialogSureChooseStage.vue'
import DialogSureNextStage from '@/components/DialogSureNextStage.vue'
const gameStore = useGameStore()
</script>

<template>
  <div
    v-if="gameStore.weAreInGame && ['stage', 'choose-stage'].includes(gameStore.progressStatus)"
    class="game"
  >
    <TestConsole />
    <HeaderMobile />
    <HeaderDesktop />

    <RouterView />
    <BottomMobile />
    <DialogSureChooseStage />
    <DialogSureNextStage />
  </div>
</template>

<style scoped>
.game {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 100vh;
}
.game > * {
  width: 100%;
}

@media (min-width: 1024px) {
  .game {
    background-image: url('@/assets/path-line-desktop.svg'),
      linear-gradient(90deg, #05081b 5%, #09124e 100%);
    background-size: 100%, auto;
    background-repeat: no-repeat, no-repeat;
    background-position:
      0% 222px,
      center 120%;
  }
}
</style>
